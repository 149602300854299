import Logo from "assets/logo-klover.svg";
import React, { useEffect } from "react";
import linkFirebaseMutation from "operations/mutations/linkFirebase.mutation";
import { CUSTOM_EVENT_NAME } from "../constants";
import { ConfirmationResult, User, linkWithPhoneNumber } from "firebase/auth";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import { LinkFirebaseInput } from "types/graphql/generated";
import { sendOnboardingEvent } from "../tracking";
import { setAuthToken } from "main";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Styled from "../App.styles";
import * as mParticle from '../tracking/mparticle';
import * as yup from "yup";

interface Step2Props {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  user: User | null;
  phone: string;
  setErrorPhoneAlreadyExists: React.Dispatch<React.SetStateAction<boolean>>;
  confirmationResult?: ConfirmationResult;
  setConfirmationResult: React.Dispatch<
    React.SetStateAction<ConfirmationResult | undefined>
  >;
}

const Step2 = ({
  activeStep,
  setActiveStep,
  user,
  phone,
  setErrorPhoneAlreadyExists,
  confirmationResult,
  setConfirmationResult,
}: Step2Props) => {
  interface FormInputs {
    otp: string;
  }

  const validationSchema = yup
    .object({
      otp: yup.string().min(6).max(6).required(),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      otp: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const [linkFirebase, { data, loading, error }] = useMutation(linkFirebaseMutation);

  const handleSendAgain = () => {
    if (!user) return;
    linkWithPhoneNumber(user, phone, window.recaptchaVerifier)
      .then((confirmationResult) => {
        setConfirmationResult(confirmationResult);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    confirmationResult
      ?.confirm(data.otp)
      .then((result) => {
        result.user.getIdToken().then((token) => {
          setAuthToken(token);
          linkFirebase({
            variables: {
              input: {
                isSmsOptIn: true,
                phoneNumber: result.user.phoneNumber,
              } as unknown as LinkFirebaseInput,
            },
          });
        });
      })
      .catch((error) => {
        console.error(error);
        if (error.code === "auth/account-exists-with-different-credential") {
          setErrorPhoneAlreadyExists(true);
          setActiveStep(1);
          user?.delete();
        }
      });
  };

  useEffect(() => {
    if (data && user) {
      mParticle.login({
        email: user.email,
        userId: data.linkFirebase.userId
      }).then(() => {
        sendOnboardingEvent({ action: CUSTOM_EVENT_NAME.PHONE_VERIFICATION_COMPLETE });
        setActiveStep(3);
      });
    }
  }, [data, user]);

  useEffect(() => {
    if (error) {
      user?.delete();
    }
  }, [error]);

  return (
    <Styled.Step2 $activeStep={activeStep}>
      <Styled.Logo src={Logo} />

      <Styled.Title>One more step...</Styled.Title>

      <Styled.Description>
        Please enter the 6-digit verification code to confirm that you received
        the text message.
      </Styled.Description>

      <Styled.OtpForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing="10px">
          <Grid item xs={12}>
            <Controller
              name="otp"
              control={control}
              render={({ field }) => (
                <Styled.TextFieldCentered
                  fullWidth
                  error={!!errors.otp}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Styled.Button
              fullWidth
              variant="contained"
              disabled={loading}
              type="submit"
            >
              Next
            </Styled.Button>
          </Grid>
        </Grid>
      </Styled.OtpForm>

      <Styled.CTA>
        Didn&apos;t get the code?{" "}
        <a href="#" onClick={handleSendAgain}>
          Send again
        </a>
        .
      </Styled.CTA>

      <Styled.Subtle>
        Having issues with the process? Please <a href="#">contact support</a>.
      </Styled.Subtle>
    </Styled.Step2>
  );
};

export default Step2;
